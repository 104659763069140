<template>
    <div
        class="gst-privacy-policy content-max-width"
        :class="{
            'is-modal': hasParentModal
        }">
        <div
            class="d-flex mb-4"
            :class="{
                'mt-4 ': hasParentModal
            }">
            <ModalLinkOverRouterLink
                class="gst-privacy-policy__btn d-flex align-items-center"
                :to="{ name: 'termsOfUse' }"
                :has-modal-parent="hasParentModal">
                <BaseIcon
                    symbol-id="icons--back_arrow"
                    class="u-mouse-pointer gst-privacy-policy__btn__icon" />
                {{ backBtnText }}
            </ModalLinkOverRouterLink>
        </div>
        <component :is="component" />
    </div>
</template>
<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import StaticPageMixin from '@core/mixins/StaticPageMixin';
    import ParentModalMixin from '@core/mixins/ParentModalMixin';
    import ModalLinkOverRouterLink from '@core/shared/components/misc/ModalLinkOverRouterLink';
    import PrivacyPolicy_de from './PrivacyPolicy_de';
    import PrivacyPolicy_es from './PrivacyPolicy_es';
    import PrivacyPolicy_it from './PrivacyPolicy_it';
    import PrivacyPolicy_nl from './PrivacyPolicy_nl';
    import PrivacyPolicy_fr  from './PrivacyPolicy_fr.vue';
    import PrivacyPolicy_en  from './PrivacyPolicy_en.vue';

    export default {
        name: 'PrivacyPolicy',
        components: {
            /* eslint-disable vue/no-unused-components */
            PrivacyPolicy_en,
            PrivacyPolicy_fr,
            PrivacyPolicy_de,
            PrivacyPolicy_es,
            PrivacyPolicy_it,
            PrivacyPolicy_nl,
            BaseIcon,
            BaseButton,
            ModalLinkOverRouterLink
            /* eslint-enable vue/no-unused-components */
        },
        mixins: [ StaticPageMixin, ParentModalMixin ],
        computed: {
            component() {
                return `PrivacyPolicy_${this.language}`;
            },
            backBtnText() {
                return `${this.$t( '_common:buttons.backTo' )} ${this.$t( '_common:terms.termsOfUse' )}`;
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-privacy-policy {
    h1 {
        line-height: 28px;
        margin: theme-spacing( 4 ) theme-spacing( 0 ) theme-spacing( 6 ) theme-spacing( 0 );
        color: #1F262D;
        font-size: font-size( 'xxxxl' );
        font-weight: font-weight( 'large' );
    }

    h6 {
        line-height: line-height( 'l' );
        margin-bottom: theme-spacing( 4 );
        color: #1F262D;
        font-size: font-size( 's' );
        font-weight: font-weight( 'large' );
    }

    p {
        line-height: line-height( 'l' );
        color: #1F262D;
        font-size: font-size( 's' );
        font-weight: font-weight( 'regular' );
    }

    p:first-of-type {
        color: theme-color( 'tertiary' );
    }

    p:last-of-type {
        margin-bottom: 64px !important;
    }

    .gst-privacy-policy__btn {
        line-height: 24px;
        color: theme-color( 'anchor' );
        font-size: font-size( 's' );
        font-weight: font-weight( 'medium' );
        min-height: 24px;

        &:hover {
            background-color: transparent;
            color: theme-color( 'anchor' );
        }

        .gst-privacy-policy__btn__icon {
            margin-right: theme-spacing( 2 );

            .gst-svg-icon {
                fill: theme-color( 'anchor' );
            }
        }
    }

    @include mobile-only {
        h6,
        p {
            margin-bottom: theme-spacing( 2 ) !important;
            letter-spacing: -0.35px;
        }

        p {
            line-height: line-height( 'xxxl' );
        }

        h6 {
            font-weight: font-weight( 'bold' );
        }

        h6:first-of-type {
            letter-spacing: -0.47px;
        }

        p:last-of-type {
            margin-bottom: 139px !important;
        }
    }
}
</style>
