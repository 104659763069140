let mixin = {
    data() {
        return {
            hasParentModal: false
        };
    },
    mounted() {
        this.hasParentModal = this.$el && !!this.$el.closest( '.v-dialog__content' ) || false;
    }
};

export default mixin;
