<template>
    <div class="gst-terms-of-use content-max-width pt-4">
        <h1>Privacy Policy es</h1>

        <h6>Agreement between User and Lifestyle</h6>

        <p>Last updated on March 16, 2020</p>

        <p>
            Welcome! The following are the terms of use ("Terms") that govern your use of Live Nation and Ticketmaster's sites
            and mobile applications - including but not limited to www.livenation.com, www.ticketmaster.com, www.ticketsnow.com,
            and www.ticketexchangebyticketmaster.com - (collectively, the "Site"), and your purchase, possession, or use of any Live
            Nation or Ticketmaster tickets, products, or services.
        </p>

        <p>
            Our Privacy Policy, Purchase Policy, and any other policies, rules or guidelines that may be applicable to particular offers or
            features on the Site are also incorporated into these Terms. By visiting or using the Site, you expressly agree to these Terms, as updated from time to time.
        </p>

        <h6>NOTICE REGARDING FUTURE CHANGES TO TERMS:</h6>

        <p>
            We may make changes to these Terms at any time. Any changes we make will be effective immediately when we post a revised
            version of these Terms on the Site. The "Last Updated" date above will tell you when these Terms were last revised. By continuing
            to use this Site after that date, you agree to the changes.
        </p>

        <h6>NOTICE REGARDING ARBITRATION AND CLASS ACTION WAIVER:</h6>

        <p>
            These Terms contain an arbitration agreement and class action waiver, whereby you agree that any dispute or claim relating in
            any way to your use of the Site, or to products or services sold, distributed, issued, or serviced by us or through us, will be resolved
            by binding, individual arbitration, rather than in court, and you waive your right to participate in a class action lawsuit or class-wide
            arbitration. We explain this agreement and waiver, along with some limited exceptions, in Section 17, below.
        </p>

        <p>
            Please note that while some of the events listed on the Site may appeal to children, the Site is not targeted at children under
            the age of 13, and they are not permitted to use the Site. We strongly encourage all parents and guardians to monitor the
            Internet use by their children. If you use the Site, you affirm you are at least 13 years old.
        </p>

        <h6>1. Account Registration</h6>
        <p>
            You may browse the Site without registering for an account. You will be required to register for an account to use certain
            features of the Site, such as reserving or purchasing a ticket. Your account username may not include the name of another
            person with the intent to impersonate that person, or be offensive, vulgar or obscene. Your account username and password
            are personal to you. You will be responsible for the confidentiality and use of your username and password, and for all activities
            (including purchases) that are conducted through your account. You may not transfer or sell access to your account. We will not
            be liable for any harm related to disclosure of your username or password or the use by anyone else of your username or
            password. You may not use another user's account without that user's permission. You will immediately notify us in writing
            if you discover any unauthorized use of your account or other account-related security breach. We may require you
            to change your username and/or password if we believe your account is no longer secure or if we receive a complaint
            that your username violates someone else's rights. You will have no ownership in your account or your username.
            We may refuse registration, cancel an account or deny access to the Site for any reason.
        </p>

        <h6>2. Code of Conduct</h6>
        <p>You agree that you will comply with all applicable laws, rules and regulations, and that you will not:</p>

        <p>Use the Site for any unlawful purpose;</p>

        <p>Express or imply that any statements you make are endorsed by us, without our prior written consent;</p>

        <p>Impersonate any person or entity, whether actual or fictitious, including any employee or representative of our company;</p>

        <p>
            Submit (a) any content or information that is unlawful, fraudulent, libelous, defamatory, or otherwise objectionable, or
            infringes our or any third party's intellectual property or other rights; (b) any non-public information about companies
            without authorization; or (c) any advertisements, solicitations, chain letters, pyramid schemes, surveys, contests, investment
            opportunities or other unsolicited commercial communication;
        </p>

        <h6>3. Privacy Policy</h6>
        <p>
            A privacy policy is a statement or legal document (in privacy law) that discloses some or all of the ways a party gathers, uses,
            discloses, and manages a customer or client's data.[1] Personal information can be anything that can be used to identify an
            individual, not limited to the person's name, address, date of birth, marital status, contact information, ID issue, and expiry
            date, financial records, credit information, medical history, where one travels, and intentions to acquire goods and services.[2]
            In the case of a business, it is often a statement that declares a party's policy on how it collects, stores, and releases personal
            information it collects. It informs the client what specific information is collected, and whether it is kept confidential, shared
            with partners, or sold to other firms or enterprises.[3][4] Privacy policies typically represent a broader, more generalized treatment,
            as opposed to data use statements, which tend to be more detailed and specific. As described in our
        </p>
    </div>
</template>
<script>
    export default {
        name: 'PrivacyPolicyEs'
    };

</script>
