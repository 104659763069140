<template>
    <router-link v-if="!hasModalParent" :to="to">
        <slot></slot>
    </router-link>
    <a v-else href="JavaScript:void(0);" @click="onClickLinkDo">
        <slot></slot>
    </a>
</template>

<script>
    export default {
        name: 'ModalLinkOverRouterLink',
        props: {
            to: {
                type: [ String, Object ],
                required: true
            },
            hasModalParent: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            onClickLinkDo( ) {
                const getParentModalComponent = () => {
                    let parent = this.$parent;
                    while ( parent && !parent.$el.classList.contains( 'v-dialog__content' ) ) {
                        parent = parent.$parent;
                    }

                    return parent.$children[0];
                };
                const parentModalComponent = getParentModalComponent( );
                parentModalComponent.$emit( 'changeModalContent', this.to );
            }
        }
    };
</script>